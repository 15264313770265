import styled from 'styled-components'
import { Section } from './default-template.styled'

export const SubHeading = styled.p`
    font-size: 22px;
    font-weight: 300;
    font-family: Ubuntu, sans-serif
`

export const LeftContainer = styled.div`
    width: 80%;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 30px;

    @media(max-width: 1200px) {
        width: 100%;
    }


`

export const RightContainer = styled.div`
    width: 20%;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 30px;
    display: flex;    
    flex-direction: column;
    @media(max-width: 1200px) {
        flex-direction: row !important;
        width: 100%;
    }

`

export const ArticleContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;

    @media(max-width: 1200px) {
        flex-direction: column;
    }
`

export const ImageContainer = styled.div`
padding-bottom: 20px;

@media(max-width: 1200px) {
    padding-right: 40px;
}

@media(max-width: 768px) {
    padding-right: 10px;
}


`

export const ArticleSection = styled(Section)`
`