import React from 'react'
import {graphql} from 'gatsby'
import Layout from '../components/layout'
import { Heading, Text, CoverImage } from '../styles/default-template.styled'
import { MainContainer } from '../styles/common.styled'
import PortableText from '../components/BlockContent/PortableText'
import { SubHeading, LeftContainer, RightContainer, ImageContainer, ArticleSection, ArticleContainer } from '../styles/article.styled'
import SEO from '../components/seo'


export const query = graphql`
    query getArticle($slug: String!) {
        sanityArticle(slug: {current: {eq: $slug}}) {
            title,
            slug {
                current
            }
            subTitle,
            excerpt
            _rawBody,
            image {
                asset {
                    fluid(maxWidth: 1200, maxHeight: 300) {
                        ...GatsbySanityImageFluid
                    }
                    url
                }
            }
            moreImages {
                _key
                asset {
                    fluid(maxWidth: 300) {
                        ...GatsbySanityImageFluid
                    }
                    url
                }
            }
            seo {
                description,
                image {
                    asset {
                        url
                    }
                }
            }
        }
    }
`


const ArticleTemplate = ({data}) => {
    const {title, _rawBody, excerpt, subTitle, image, moreImages, seo} = data.sanityArticle
    return (
        <Layout>
            <SEO title={title} seo={seo} />
            <ArticleSection>
                <MainContainer>
                    <ArticleContainer>
                        <LeftContainer>
                            <Heading>{title}</Heading>
                            <SubHeading>{subTitle}</SubHeading>
                            <Text>{excerpt}</Text>
                            <Text>
                                <PortableText blocks={_rawBody} />
                            </Text>
                        </LeftContainer>
                        <RightContainer>
                            {moreImages.map(({asset, _key}) => (
                                <ImageContainer key={_key}>
                                    <img src={asset.url} alt="små bilder"/>
                                </ImageContainer>
                            ))}                       
                        </RightContainer>
                    </ArticleContainer>
                </MainContainer>
                <CoverImage fluid={image.asset.fluid} />
            </ArticleSection>
        </Layout>
        
    )
}

export default ArticleTemplate